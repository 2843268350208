import 'owl.carousel';

$(document).ready(function(){
  var $window = $(window);

  if($window.width() <= 480) {
    resetClass();
  }

  $window.on('resize', function() {
    if($window.width() <= 480) {
      resetClass();
    }
  });

  var owlBenefit = $('.benefit__slider');
  owlBenefit.owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    autoplay:false,
    autoplayTimeout:6000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:1,
            nav:true,
            dots: false
        }
    }
  });

  // Listen to owl events:
  owlBenefit.on('changed.owl.carousel', function(event) {
    var page = event.item.index;

    $('.benefit__slider__count__first').html('0' + (page - 3));
  });

  $('.reviews__slider').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    responsive:{
        0:{
            items:1,
            nav:true
        }
    }
  });

  $('.js-author-mobile-nav').on('click', function() {
    $('.mobile-nav').toggleClass('author-mobile-nav-open');
  });

  $('.questions__item__title').on('click, mouseover', function() {
    var $parent = $(this).parents('.questions__item');

    resetClass();
    $parent.addClass('open');
  });

  function resetClass() {
    var $parentsQuestions = $('.questions__item');
    $parentsQuestions.removeClass('open');
  }
});